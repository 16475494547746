import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import VuePapaParse from 'vue-papa-parse'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSocketIOExt from 'vue-socket.io-extended'
import io from 'socket.io-client'
import * as Sentry from '@sentry/vue'
import i18n from '@/plugins/i18n'
import FlagIcon from 'vue-flag-icon'

const URL = process.env.VUE_APP_API_BASE_URL.includes('localhost')
  ? `ws://${window.location.host}`
  : 'wss://socket.draudimobites.lt'
const socket = io(URL, {
  autoConnect: false,
})

import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context(
  // The relative path of the components folder
  './components',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

Vue.use(VueSocketIOExt, socket)

Vue.config.productionTip = false
Vue.use(FlagIcon)
Vue.use(Vuelidate)
Vue.use(VuePapaParse)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA6u3vh8LfDZsXyAQ1-tRU5abixhcxnYWk',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
})

// init sentry unless in devmode
if (!process.env.VUE_APP_API_BASE_URL.includes('localhost')) {
  Sentry.init({
    Vue,
    dsn: 'https://50bd2275c1bed2948d0d89c4c25e1de7@o4505124482842624.ingest.sentry.io/4505906213683200',
  })
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
